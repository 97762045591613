import React from 'react'
import "./stats.css"

const Stats = () => {
  const statsData = [
    {
      score: 10,
      msg: "Experience this week"
    },
    {
      score: 15,
      msg: "Experience overall"
    },
    {
      score: 11,
      msg: "Courses this week"
    },
  ];
  return (
    <div className="stats-container">
      <div className="stats-top">
        <h3>Your Stats</h3>
      </div>
      <div className="stats-box-container">
        {
          statsData.map((data =>
            <div className="stats-box" key={data.msg}>
              <h2 className="score">
                {data.score}
              </h2>
              <p className="msg">
                {data.msg}
              </p>
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default Stats