import React from "react";
import "./classes.css";
import { AiOutlineBell } from "react-icons/ai";

const Classes = () => {
  const cardData = [
    {
      title: "26 Feb 2022, 9am",
      dec: "Class 1 : basics of JavaScript. Lorem ipsum dolor sit, amet",
      img: "https://bit.ly/3C6uCJU",
    },
    {
      title: "27 Feb 2022, 9am",
      dec: "Class 2 : basics of React. Lorem ipsum dolor sit, amet",
      img: "https://bit.ly/3Ai613x",
    },
    {
      title: "1 Mar 2022, 9am",
      dec: "Class 3 : basics of Angular. Lorem ipsum dolor sit, amet",
      img: "https://bit.ly/3PrnevA",
    },
  ];

  return (
    <div className="classes-container">
      <div className="classes-top">
        <h3>Your upcoming classes of courses</h3>
        <p>see all</p>
      </div>

      <div className="classes-card-container">
        {cardData.map((card) => (
          <div className="classes-card" key={card.img}>
            <div className="classes-card-img">
              <img height="150" src={card.img} alt="img" />
            </div>
            <div className="classes-card-body">
              <h2 className="classes-card-title">{card.title} </h2>
              <p className="classes-card-dec" style={{ fontWeight: "bold" }}>
                {card.dec.slice(0, 10)}
                <span style={{ fontWeight: "normal" }}>
                  {card.dec.slice(10)}{" "}
                </span>
              </p>
              <p className="classes-card-by">By Abhishek</p>
              <a
                href="/"
                className="classes-card-btn"
                style={{ display: "flex", alignItems: "center", width: "50%" }}
              >
                <AiOutlineBell
                  style={{ marginRight: "5px", fontSize: "20px" }}
                />{" "}
                Remind Me
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Classes;
