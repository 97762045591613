import React from "react";
import "./experiences.css";
import cardImg1 from "../../../../assets/card-img1.jpg";
import cardImg2 from "../../../../assets/card-img2.jpg";
import cardImg3 from "../../../../assets/card-img3.jpg";
import { AiOutlineBell } from "react-icons/ai";

const Experiences = () => {
  return (
    <div className="experiences-container">
      <div className="experiences-top">
        <h3>Your upcoming experiences</h3>
        <p>see all</p>
      </div>

      <div className="experiences-card-container">
        <div className="experiences-card mobile-hidden-card">
          <div className="card-img">
            <img height="150" src={cardImg1} alt="img" />
          </div>
          <div className="card-body">
            <h2 className="card-title">Lorem ipsum dolor </h2>
            <p className="card-dec">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit.
            </p>
            <p className="card-by">By Abhishek</p>
            <a
              href="/"
              className="card-btn"
              style={{
                background: "#07a531",
                color: "#fff",
                padding: "8px 16px",
              }}
            >
              Jion Now
            </a>
          </div>
        </div>

        <div className="experiences-card">
          <div className="card-img">
            <img height="150" src={cardImg2} alt="img" />
          </div>
          <div className="card-body">
            <h2 className="card-title">Lorem ipsum dolor </h2>
            <p className="card-dec">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit.
            </p>
            <p className="card-by">By Abhishek</p>
            <a href="/" className="card-btn" style={{display:"flex", alignItems:"center"}}>
              <AiOutlineBell style={{marginRight:"5px", fontSize:"20px"}}/> Remind Me
            </a>
          </div>
        </div>

        <div className="experiences-card">
          <div className="card-img">
            <img height="150" src={cardImg3} alt="img" />
          </div>
          <div className="card-body">
            <h2 className="card-title">Lorem ipsum dolor </h2>
            <p className="card-dec">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit.
            </p>
            <p className="card-by">By Abhishek</p>
            <a href="/" className="card-btn" style={{display:"flex", alignItems:"center"}}>
              <AiOutlineBell style={{marginRight:"5px", fontSize:"20px"}}/> Remind Me
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experiences;
