import React from 'react'
import "./mentors.css"
import personImg1 from "../../../../assets/person1.jpg";
import personImg2 from "../../../../assets/person2.jpg";
import personImg3 from "../../../../assets/person3.jpg";
import profilePic from "../../../../assets/profile.png";

const Mentors = () => {
  return (
    <div className="mentors-container">
      <div className="stats-top">
        <h3>New mentors on Edvolve</h3>
      </div>
      <div className="mentors-box-container">
        <div className="mentors-box">
          <div className="mentors-box-left">
            <img src={personImg1} alt="person" />
          </div>
          <div className="mentors-box-right">
            <div className="mentors-box-right-details">
              <h3>Goku SSG</h3>
              <p>Super Sayian God Form</p>
              <p>Lorem ipsum dolor sit</p>
              <p>Lorem ipsum dolor sit</p>
            </div>
            <div className="mentors-right-btn">
              <a href="/">SEE PROFILE</a>
            </div>
          </div>
        </div>

        <div className="mentors-box">
          <div className="mentors-box-left">
            <img src={personImg2} alt="person" />
          </div>
          <div className="mentors-box-right">
            <div className="mentors-box-right-details">
              <h3>Goku SSB</h3>
              <p>Super Sayian Blue Form</p>
              <p>Lorem ipsum dolor sit</p>
              <p>Lorem ipsum dolor sit</p>
            </div>
            <div className="mentors-right-btn">
              <a href="/">SEE PROFILE</a>
            </div>
          </div>
        </div>

        <div className="mentors-box">
          <div className="mentors-box-left">
            <img src={personImg3} alt="person" />
          </div>
          <div className="mentors-box-right">
            <div className="mentors-box-right-details">
              <h3>Goku UI</h3>
              <p>Ultra Instinct Form</p>
              <p>Lorem ipsum dolor sit</p>
              <p>Lorem ipsum dolor sit</p>
            </div>
            <div className="mentors-right-btn">
              <a href="/">SEE PROFILE</a>
            </div>
          </div>
        </div>

        <div className="mentors-box">
          <div className="mentors-box-left">
            <img src={profilePic} alt="person" />
          </div>
          <div className="mentors-box-right">
            <div className="mentors-box-right-details">
              <h3>Abhishek</h3>
              <p>Web Developer</p>
              <p>Lorem ipsum dolor sit</p>
              <p>Lorem ipsum dolor sit</p>
            </div>
            <div className="mentors-right-btn">
              <a href="/">SEE PROFILE</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Mentors