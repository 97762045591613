import React from "react";
import "./navbar.css";
import logo from "../../assets/logo.png";
import profilePic from "../../assets/profile.png";
import { BiSearch } from "react-icons/bi";
import { AiFillPlayCircle } from "react-icons/ai";
import { VscThreeBars } from "react-icons/vsc";

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="nav-container">
        <div className="nav-left">
          <div className="icon menu-btn">
            <VscThreeBars />
          </div>
          <div className="logo mobile-logo">
            <a href="/">
              Ed<span>volve</span>
            </a>
            <img src={logo} alt="logo" />
          </div>
          <div className="nav-logo">
            <a href="/">
              <img src={logo} alt="logo" />
            </a>
          </div>
          <ul className="nav-links">
            <li className="nav-link">
              <a href="/">Dashboard</a>
            </li>
            <li className="nav-link">
              <a href="/">Experience Zone</a>
            </li>
            <li className="nav-link">
              <a href="/">Learning Zone</a>
            </li>
          </ul>
          <div className="nav-search">
            <BiSearch className="nav-search-icon" />
            <input type="text" placeholder="Search" />
          </div>
        </div>
        <div className="nav-right">
          <div className="nav-button">
            <button>
              {" "}
              <AiFillPlayCircle className="nav-button-icon" /> Discover Zone
            </button>
          </div>
          <div className="nav-profile">
            <img src={profilePic} alt="logo" />
            <h3>Abhishek</h3>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
