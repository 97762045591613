import React from 'react'
import "./home.css"
import { IoMdHand } from "react-icons/io";
import Experiences from './dashboards_components/experiences/Experiences';
import Classes from './dashboards_components/classes/Classes';
import Stats from './dashboards_components/stats/Stats';
import Mentors from './dashboards_components/mentors/Mentors';

const Home = () => {
  return (
    <section className="home-container">
      <div className="home-top">
        <h2 style={{ display: "flex", alignItems: "center" }}>
          <IoMdHand style={{ color: "#E8BEAC" }} />
          {" "}
          Welcome back, Abhishek
        </h2>
        <p>How are we Edvolving today?</p>
      </div>

      <div className="home-dashboard">
        <div className="home-left">
          <div className="experiences">
            <Experiences />
          </div>
          <div className="classes">
            <Classes />
          </div>
        </div>
        
        <div className="home-right">
          <div className="stats">
            <Stats />
          </div>
          <div className="mentos">
            <Mentors />
          </div>
        </div>
      </div>

    </section>
  )
}

export default Home